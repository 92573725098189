import type { NextPage } from 'next'
import Head from 'next/head';
import Link from 'next/link';
import { Container } from 'src/components/container';
import { Meta } from 'src/utils/meta';
import { Routes } from 'src/utils/routes';
import styles from './pageNotFound.module.scss';

const PageNotFound = () => {
  return (
    <div>
      <Head>
        <title>PAGE NOT FOUND | {Meta.TITLE}</title>
      </Head>
      <Container>
        <div className={styles.pageNotFound}>
          <h1 className={styles.pageNotFoundHeading}>Page Not Found</h1>
          <p className={styles.pageNotFoundSymbol}>404</p>
          <p className={styles.pageNotFoundText}>お探しのページは見つかりませんでした。</p>
          <p className={styles.pageNotFoundText}>ページが更新・削除された、またはURLが違う可能性があります。<br />お手数ですが再度ご確認ください。</p>
          <Link href={Routes.HOME}>
            <a className={styles.pageNotFoundLink}>サイトトップへ</a>
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default PageNotFound
